<template>
  <b-container :class="mainClass" :style="mainStyle" fluid="sm">
    <div class="text-center my-0 py-0">
      <b-icon icon="person-fill" font-scale="10" variant="secondary" />
      <h1 class="text-center mt-1 mb-3">Connexion</h1>
    </div>

    <b-form @submit="onSubmit">
      <FormRowFullCol>
        <b-input-group size="lg">
          <InputPrependIcon icon="person-fill" />

          <b-form-input
            type="text"
            placeholder="Utilisateur"
            v-model="form.username"
            autocomplete="true"
          ></b-form-input>
        </b-input-group>
      </FormRowFullCol>

      <FormRowFullCol>
        <b-input-group size="lg">
          <InputPrependIcon icon="key-fill" />
          <b-form-input
            :type="passwordFieldType"
            placeholder="Mot de passe"
            v-model="form.password"
            autocomplete="true"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant="info"
              @click="showPassword()"
              class="bounce_effect"
            >
              <b-icon :icon="iconPasswordVisible" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </FormRowFullCol>

      <FormRowFullCol>
        <b-button
          variant="info"
          class="text-center slide_in_from_right"
          size="lg"
          type="submit"
          large
          block
          >Se connecter</b-button
        >
        <!--  -->
      </FormRowFullCol>
    </b-form>

    <hr class="bg-info hr-50" />

    <!-- Return to forget password -->
    <FormRowFullCol>
      <b-link
        tag="a"
        to="forgot-password"
        class="text-info text-right slide_in_from_left"
        >Mot de passe oublié ?</b-link
      >
      <!--  -->
    </FormRowFullCol>

    <!-- Return to register -->
    <FormRowFullCol class="text-right">
      <b-link
        tag="a"
        to="register"
        class="text-info text-right slide_in_from_left"
        >Vous n'avez pas de compte ?</b-link
      >
      <!--  -->
    </FormRowFullCol>
  </b-container>
</template>

<script>
export default {
  components: {
    FormRowFullCol: () => import('../components/FormRowFullCol'),
    FormRowDualCol: () => import('../components/FormRowDualCol'),
    FormRowHalfCol: () => import('../components/FormRowHalfCol'),
    InputPrependIcon: () => import('../components/InputPrependIcon'),
    FormTermsAndUse: () => import('../components/FormTermsAndUse'),
    FormInvalidFeedback: () => import('../components/FormInvalidFeedback'),
  },
  data() {
    return {
      mainClass: ['mt-5'],
      mainStyle: ['max-width: 400px'],
      isPasswordVisible: false,
      form: {
        username: '',
        password: '',
        captcha: '',
      },
    }
  },
  computed: {
    passwordFieldType() {
      return this.isPasswordVisible ? 'text' : 'password'
    },
    iconPasswordVisible() {
      return this.isPasswordVisible ? 'eye-slash-fill' : 'eye-fill'
    },
  },
  mounted() {
    // this.$store.dispatch('store_csrf/get')
    this.$store.dispatch('store_csrf/test')
  },
  beforeDestroy() {
    this.onDestroy()
  },
  methods: {
    showPassword() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    onSubmit(event) {
      event.preventDefault()

      event.preventDefault()
      this.$store.dispatch('store_check_form/overlayState', true)

      this.$root.timeout = setTimeout(() => {
        this.$store.dispatch('store_check_form/overlayState', false)
        this.$store.dispatch('store_auth/login', this.form)
        this.$store.dispatch('store_debug/set', this.form)
      }, 2000)
    },
    onDestroy() {
      clearTimeout(this.$root.timeout)
      this.$store.dispatch('store_csrf/delete')
      this.form = null
      this.$store.dispatch('store_debug/set')
    },
  },
}
</script>
